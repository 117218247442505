.cart-button-sidebar {
    position: fixed;
    right: 0;
    top: 40%;
    z-index: 999;
    width: 80px;
    text-align: center;
    background: #b9ababc2;
    cursor: pointer;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.cart-button-sidebar .cart-button-sidebar-button .icon i{
	font-size: 40px;
    color: #000;
}
.cart-button-sidebar .cart-button-sidebar-button .icon  span {
    display: block;
    width: 100%;
    font-size: 12px;
    font-weight: 700;
    color: #333;
}
.cart-button-sidebar .cart-button-sidebar-button .icon {
    padding: 6px;
    padding-bottom: 0px;
}
.cart-button-sidebar .cart-button-sidebar-button .conent {
    background: #000000ab;
    padding: 3px 0px;
    padding-top: 6px;
    border-bottom-left-radius: 8px;
    /*border-bottom-right-radius: 8px;*/
}
.cart-button-sidebar .cart-button-sidebar-button .conent strong {
    color: #fff;
    font-size: 13px;
    line-height: 1;
}

/* MiniCart */
.mini-cart-area {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 320px;
    background: #fff;
    height: 100vh;
    z-index: 999;
    width: 100%;
    border-left: 1px solid #ddd;
    transition: all .4s ease;
	margin-right: -330px;
}
.mini-cart-area.active {
    margin-right: 0px;
}
.mini-cart-header {
    height: 50px;
    background: #ddd;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mini-cart-header  h2 {
    line-height: 1;
    font-size: 16px;
    padding-left: 15px;
}
.mini-cart-header  h2 i {
    font-size: 30px;
    margin-right: 8px;
    color: #0d0617;
}
.mini-cart-header h2 span {
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 900;
}
.mini-cart-header  .mini-cart-close {
    margin-right: 15px;
    font-size: 29px;
    transition: all .3s ease;
    color: transparent;
    -webkit-text-stroke: 1px #1d2122;
    opacity: 1;
	cursor: pointer;
}
.times-custom {
    width: 24px;
    height: 24px;
    position: relative;
    display: block;
}
i.times-custom:after {
	content: "";
	clear: both;
	display: block;
	position: absolute;
	width: 100%;
	height: 2px;
	background: #000;
	transform: rotate(45deg);
	top: 50%;
	transition: all .4s ease-in-out;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	-ms-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
}
i.times-custom:before {
    content: "";
    clear: both;
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background: #000;
    transform: rotate(-45deg);
    top: 50%;
	transition: all .4s ease-in-out;
}
.times-custom:hover::after{
	background: red;
}
.times-custom:hover::before{
	background: red;
}
.mini-cart-item-list .single-c-item i.times-custom:after {
    width: 16px;
}
.mini-cart-item-list .single-c-item i.times-custom:before {
    width: 16px;
}
.mini-cart-item-list .single-c-item i.times-custom:hover:after {
    background: rgb(255, 91, 91);
}
.mini-cart-item-list .single-c-item i.times-custom:hover:before {
	background: rgb(255, 91, 91);
}
.mini-cart-footer {
    height: 50px;
    background: #ddd;
    width: 100%;
    display: flex;
    align-items: center;
}
.mini-cart-footer .checkout-button {
    width: 50%;
}
.mini-cart-footer .checkout-button a {
    width: 100%;
    text-align: center;
    display: inline-block;
    height: 50px;
    background: #3b60c9;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
    transition: all .3s ease;
}
.mini-cart-footer .checkout-button a:hover{
	background: #000;
}
.mini-cart-footer  .subtotal-button {
    text-align: center;
    width: 50%;
}
.mini-cart-footer  .subtotal-button strong {
    font-size: 15px;
    font-weight: 700;
    color: #666;
}
.mini-cart-body {
    height: calc(100vh - 100px);
    overflow-y: auto;
}
.mini-cart-item-list .single-c-item {
    overflow: hidden;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding: 14px 16px;
    position: relative;
    padding-left: 105px;
    min-height: 90px;
}
.mini-cart-item-list .single-c-item img {
    width: 58px;
    position: absolute;
    left: 40px;
    border-radius: 5px;
    top: 16px;
}
.mini-cart-item-list .single-c-item .content{
    padding-right:18px;
}
.mini-cart-item-list .single-c-item .content strong {
    font-size: 14px;
    line-height: 19px;
    display: block;
    color: #000;
    font-weight: 600
}
.mini-cart-item-list .single-c-item .content .qtty span {
    color: #999;
    font-size: 13px;
    font-weight: 400;
}
.mini-cart-item-list .single-c-item  i {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #999;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 14px;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
}
.mini-cart-item-list .single-c-item  i:hover{
	color: rgb(255, 91, 91);
}
.mini-cart-item-list .single-c-item  .cart-quantity {
    position: absolute;
    left: 0px;
    width: 28px;
    top: 20px;
}

.mini-cart-item-list .single-c-item .qty-loader-main{
    position: absolute;
    left: 37px;
    top: 15px;
}

.mini-cart-item-list .single-c-item  .cart-quantity .input-group {
    width: 100%;
}
.mini-cart-item-list .single-c-item .cart-quantity .input-group span.input-group-text.minus {
    position: relative;
}
.mini-cart-item-list .single-c-item .cart-quantity .input-group span.input-group-text.minus:after {
    content: "";
    clear: both;
    display: block;
    position: absolute;
    width: 11px;
    height: 1.5px;
    background: #fff;
}
.mini-cart-item-list .single-c-item  .cart-quantity .input-group span.input-group-text {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10px;
    border: none;
    border-radius: 0px;
    background: #fff;
    position: relative;
    cursor: pointer;
}
.mini-cart-item-list .single-c-item .cart-quantity span.input-group-text.up:before {
    content: "";
    clear: both;
    display: block;
    position: absolute;
    width: 10px;
    height: 1px;
    background: #333;
    transform: rotate(-45deg);
    left: 5px;
}
.mini-cart-item-list .single-c-item .cart-quantity span.input-group-text.up:after {
    content: "";
    clear: both;
    display: block;
    position: absolute;
    width: 10px;
    height: 1px;
    background: #333;
    transform: rotate(45deg);
    right: 5px;
}
.mini-cart-item-list .single-c-item .cart-quantity span.input-group-text.down:before {
    content: "";
    clear: both;
    display: block;
    position: absolute;
    width: 10px;
    height: 1px;
    background: #333;
    transform: rotate(-145deg);
    left: 5px;
}
.mini-cart-item-list .single-c-item .cart-quantity span.input-group-text.down:after {
    content: "";
    clear: both;
    display: block;
    position: absolute;
    width: 10px;
    height: 1px;
    background: #333;
    transform: rotate(145deg);
    right: 5px;
}
.mini-cart-item-list .single-c-item  .cart-quantity .input-group  .form-control.text-center {
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    padding: 0;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
	border: none;
}
div[disabled] {
    pointer-events: none;
    opacity: 0.7;
}
@media (max-width:400px){
    .cart-button-sidebar .cart-button-sidebar-button .icon {
        padding: 2px;
    }
    .cart-button-sidebar .cart-button-sidebar-button .icon i {
        font-size: 26px;
        margin-top: 5px;
    }
    .cart-button-sidebar .cart-button-sidebar-button .icon  span {
        font-size: 10px;
        line-height: 1.8;
    }
    .cart-button-sidebar .cart-button-sidebar-button .conent {
        padding: 3px 0px;
        padding-top: 2px;
    }
    .cart-button-sidebar .cart-button-sidebar-button .conent strong {
        font-size: 12px;
    }
}
