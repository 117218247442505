.modal {
    .custom-single-select-block .custom-select-box:disabled{
        opacity: 0.5;
    }
    .modal-dialog {
        height: calc(100vh - 50px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .modal-content {
        border-radius: 10px
    }

    .modal-header {
        padding: 20px 30px;
    }

    .modal-header .modal-title {
        font-size: 2rem;
        line-height: 1.4;
        font-weight: 400;
        font-style: normal;
        margin-bottom: 0;
    }

    .modal-header .close {
        margin: -3px -1rem -1rem auto;
        font-size: 30px;
        font-weight: normal;
        padding: 0 1rem 0 0;
    }

    .modal-body {
        //height: calc(100vh - 150px);
        min-height: calc(100vh - 450px);
        max-height: calc(100vh - 150px);
        overflow: auto;
    }

    .modal-inside {
        padding-left: 24px;
        padding-right: 24px;

        .grp_with_checkbox {
            display: flex;
            justify-content: space-between;

            .input-block, .multi_select_block, .custom-single-select-block, .facebook-link, .linkdin-link {
                width: 88%;
            }

            .input-checkbox-block {
                text-align: center !important;
                width: 110px;

                .check_top_level {
                    margin-top: 10px;
                    margin-bottom: 0;
                }
            }
        }

        .grp_with_checkbox.double_drop {
            .multi_select_block, .custom-single-select-block {
                width: 43%;
                margin-right: 10px;
            }
        }
    }

    .input-block label .label_name,
    .duration label .label_name,
    input::placeholder,
    .simple_textarea_block label .label_name,
    .input-checkbox-block .check_top_level,
    .custom-single-select-block label .label_name,
    .input-block-group .label_name{
        color: rgba(0, 0, 0, 0.45) !important;
        font-weight: normal !important;
        font-size: 14px !important;
        opacity: 1;
    }

    .input-block .input {
        height: 35px;
        width: 100%;
    }

    .input-block .input, .simple_textarea_block textarea, .custom-single-select-block .custom-select-box, .input-block-group .input-container .img-input-field, .input-block-group .input-container .input-img-inside {
        border: 1px solid #4f4f4f;
        border-radius: 6px !important;
        padding: 15px;
        color: #4F4F4F;
        font-size: 14px;
    }

    .input-block-group .input-container .img-input-field{
        border-left: none;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        height: 35px;
    }

    .input-block-group .input-container .input-img-inside{
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        height: 35px;
        padding: 5px;
    }

    .custom-single-select-block .custom-select-box{
        height: 35px;
        padding: 0 15px;
        background-size: 12px;
        background-position-y: 14px;
    }

    .input-block .input:focus, .input-block-group .input-container .img-input-field:focus {
        border: 2px solid #4f4f4f;
    }

    .multi_select_block {
        margin-bottom: 5px;
    }

    .multiselect-container {
        border: 1px solid #4f4f4f;
        border-radius: 6px;
        height: 40px;
    }

    .multiselect-container {

        .search-wrapper {
            border: none !important;
            height: 35px !important;
            padding: 0 15px;
            background-position-y: 14px !important;

            .chip {
                padding: 2px 10px;
            }
        }
    }

    #month .chip {
        margin-top: 3px;
    }

    .multi_select_block label .label_name {
        color: rgba(0, 0, 0, 0.45) !important;
        font-weight: normal !important;
        font-size: 14px !important;

    }

    .action-block button {
        height: 35px;
        padding: 10px 20px;
        width: auto;
    }
}
