.action-block {
    display: inline-flex;

    button {
        width: 200px;
        height: 45px;
        border-radius: 3px;
        padding: 0;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #ffffff;
        cursor: pointer;

        &.form-submit {
            background: #013a6d;
            color: #ffffff;
        }

        &.form-cancel {
            background: #F2F2F2;
            color: #013A6D;
            margin-right: 20px;
        }

        &.form-cancel:disabled .social_icon{
            color: #013a6d;
        }
    }


    button:disabled {
        //background: #86aed1;
        cursor: not-allowed;
    }

    a {
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        text-align: right;
        color: #828282;
        text-decoration: none;

        span {
            color: #013a6d;
            font-weight: bold;
            font-size: 14px;
        }
    }

}
