@import './variables.scss';

/**
* Template Name: Starter Pack
* Template URL:

*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@import "./node_modules/placeholder-loading/src/scss/placeholder-loading.scss";
body {
    background-color: #F3F2EF;
    word-break: break-word;
}
#page-content-wrap .about-page-content-wrap {
  background-color: #F3F2EF;
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}

iframe {
    border: none;
}

.asterisk:after {
    content: " * ";
    color: red;
    font-weight: bold;
    min-width: 160px;
}

.forgot-timer {
    font-size: 16px;
}

.disabled {
    pointer-events: none;
    cursor: default;
    background: transparent !important;
    border: none;
    opacity: 0.5;
}

.slider-active-wrap, .owl-carousel {
    display: block;
}

#upcoming-area .owl-dots {
    display: none !important;
}


#upcoming-area .owl-carousel .owl-nav button.owl-prev,
#upcoming-area .owl-carousel .owl-nav button.owl-next,
#upcoming-area .owl-carousel button.owl-dot {
    background-color: #5481ff !important;
    width: 45px !important;
    height: 45px !important;
    font-size: 3.5rem;
    line-height: 45px;
    text-align: center;
    margin-bottom: 5px;
    transition: all .2s ease-out;
    display: block;
}

#header-area .preheader-area .btn-auth:focus {
    color: #FFFFFF;
}

.section-padding {
    padding: 70px 0;
}

.footer-widget {
    padding: 30px 0 !important;
}

.img-left {
    padding: 0 5rem 1rem 0;
}

#page-content-wrap .about-page-content-wrap {
    padding-top: 70px;
    padding-bottom: 30px;
}
.container.family_info_section {
    margin-bottom: 50px;
}
#footer-area .footer-bottom {
    padding: 12px 0 !important;
}

#footer-area .footer-widget .single-widget-wrap .widget-title {
    margin-bottom: 0;
}
#footer-area .footer-widget .single-widget-wrap .footer-list li a {
     margin-bottom: 0;
}

.forum-page {
    min-height: calc(100vh - 316px);
    position: relative;
    .center-text {
        position: absolute;
        top: 40%;
        width: 100%;
    }
}
.signup-btn{
    background-color: #00A650;
}
.login-btn{
    background-color: #084CA1;
}
.receive-membership{
    margin-top: 5px;
    font-size: 15px;
    font-weight: bolder;
}
.checkbox_for_register{
    margin-top: 5px;
    .register-checkbox{
        .input-checkbox-block{
            .check_top_level{
                display: none;
            }
        }
    }
}
.responsibility-row{
    display: flex;
    justify-content: space-between;
}
.savebtn{
    padding:9px 26px 9px 25px!important;
}
.paynow{
    padding: 9px 11px 9px 11px !important;
}

.mr_2{
    margin-right: 10px;
}
.bg_nevy_blue{
 background-color: #013A6D;
}
.b_r_20{
    border-radius: 20px;
}
.common_btn_auto_length{
    width: auto;
    height: auto;
    outline: none;
    cursor: pointer;
    color: #084CA1;
    transition: 0.2s;
    border:1px solid #2B3539;
    padding: 3px 10px;
    font-size: 14px;

    &:focus{
        outline: none !important;
    }
}
.hover_blue_bg{
    background-color: transparent;
    &:hover {
        background-color: #084CA1;
        color: white;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .map-area{
        .card{
            height: 100%;
            .card-body{
                .embed-responsive{
                    height: 100% !important;
                }
            }
        }
    }
}
@media (max-width:576px){
    .section-padding{
        padding: 30px 0;
    }
}
.section-padding-2{
    padding-top: 70px;
    padding-bottom: 70px;
}
.section-bg{
    background: #F3F2EF;
}
.mb-30{
    margin-bottom: 30px;
}
.mt-30{
    margin-top: 30px;
}
img{
    max-width: 100%;
}
iframe {
	z-index: 1 !important;
	position: inherit !important;
}
.embed-responsive.embed-responsive-16by9::before {
	padding-top: 0px !important;
}
#slider-area .slider-active-wrap.owl-carousel {
	display: block !important;
}
.register-form-content .input-block .input-box.input.disabled {
	background: #fff !important;
}
#header-area {
	background: #FEF6F3;
}
._3vt7_Mh4hRCFbp__dFqBCI input {
	margin-top: 0px !important;
}
.height-450{
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
}
// .start-date-section span.error-message.error-image {
//     color: #EB5757;
//     font-size: 12px;
// }

button.btn-facebook:disabled, button.btn-facebook[disabled]{
    cursor: auto !important;
    background-color: #cccccc !important;
}
.mb-20{
    margin-bottom: 20px;
}
.mb-30{
    margin-bottom: 30px;
}
.breadcrumb-area{
    ul {
        li {
            display: inline-block;
            margin-right: 10px;
            font-size: 15px;
            font-weight: 400;
            color: #888;
            a {
                color: #000;
                font-weight: 600;
                span{
                    padding-left: 4px;
                }
                &:hover{
                    color: #3b60c9;
                }
            }
        }
    }
}
.cursor-pointer {
    cursor: pointer;
}
#footer-area .footer-widget .single-widget-wrap .footer-social-icons a:hover {
    -moz-opacity: 1 !important;
    -khtml-opacity: 1 !important;
    -webkit-opacity: 1 !important; 
    opacity: 1 !important; 
}