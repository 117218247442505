@media (max-width:1199.99px){
    .login-btn {
        padding: 7px 36px !important;
    }
    .snews_item_single .content {
        padding: 24px 20px !important;
    }
    #header-area {
        .header-bottom-area {
            .main-menu {
                .navbar-nav {
                    li {
                        a.nav-link {
                            margin: 0px 17px !important;
                            font-size: 15px !important;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width:991.99px){
    .section-padding{
        padding: 80px 0px !important;
    }
    .section-padding-2{
        padding-top: 80px !important;
        padding-bottom: 50px !important;
    }
    .section-headding{
        h2 {
            font-size: 45px !important;
            line-height: 50px !important;
        }
        p {
            font-size: 18px !important;
            line-height: 30px !important;
            padding-top: 4px !important;
        }
    }
    .our_message_single{
        .content {
            text-align: center !important;
            margin-top: 30px !important;
        }
    }
    #footer-area .footer-widget {
        padding-bottom: 0px !important;
    }
    .single-about-text.about-school-text {
        h2 {
            font-size: 34px !important;
            line-height: 1 !important;
        }
        h4 {
            font-size: 22px !important;
            line-height: 1 !important;
        }
    }
    .map-area {
        margin-top: 50px !important;
        overflow: hidden !important;
        margin-bottom: 50px !important;
        height: auto !important;
    }
    #slider-area .owl-dots {
        bottom: 20% !important;
        top: auto !important;
        left: 97% !important;
    }
    .breadcrumb-section {
        height: 600px !important;
    }
    .map-area {
        margin-top: 50px !important;
        overflow: hidden !important;
        margin-bottom: 50px !important;
        height: auto !important;
        height: 300px !important;
    }
    .mobile-nav-icon {
		float: right !important;
		width: 30px !important;
		height: 30px !important;
		cursor: pointer;
		z-index: 9999 !important;
		position: relative !important;
	}
	.mobile-nav-icon span {
		width: 100%;
		height: 2px;
		background: #000;
		display: block;
		margin: 8px 0px;
		-webkit-transition: all .4s ease;
		transition: all .4s ease;
	}
	.mobile-nav-icon span:nth-child(2){
		transition: all 0.4s ease-in-out;
		position: relative;
	}
	.mobile-nav-icon span:nth-child(2)::before {
		content: "";
		width: 30px;
		height: 2px;
		background: #000;
		position: absolute;
		left: 0;
		transform: rotate(0deg);
		transition: all 0.4s ease-in-out;
	}
	.mobile-nav-icon span:nth-child(2)::after {
		content: "";
		width: 30px;
		height: 2px;
		background: #000;
		position: absolute;
		left: 0;
		transform: rotate(0deg);
		transition: all 0.4s ease-in-out;
	}
	.mobile-nav-icon.active span:nth-child(2)::before {
		content: "";
		width: 30px;
		height: 2px;
		background: #000;
		position: absolute;
		left: 0;
		transform: rotate(-45deg);
		transition: all 0.4s ease-in-out;
	}
	.mobile-nav-icon.active span:nth-child(2)::after {
		content: "";
		width: 30px;
		height: 2px;
		background: #000;
		position: absolute;
		left: 0;
		transform: rotate(45deg);
		transition: all 0.4s ease-in-out;
	}
	.mobile-nav-icon.active span:nth-child(1){
		opacity: 0;
		visibility: hidden;
	}
	.mobile-nav-icon.active span:nth-child(3){
		opacity: 0;
		visibility: hidden;
	}
	.mobile-nav-icon span:nth-child(2) {
		width: 100%;
		height: 2px;
		background: #000;
		display: block;
		margin: 5px 0px;
		transition: all 0.4s ease-in-out;
		position: relative;
	}
	.mobile-nav-icon.active span:nth-child(2) {
		position: relative;
		width: 0;
	}
    .off-canvas {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 9;
        opacity: 0;
        visibility: hidden;
        cursor: crosshair;
        background: #232323;
        top: 0;
    }
    .off-canvas.active {
        opacity: 0.5;
        visibility: visible;
    }

    .header-bottom-area.active{
        opacity: 1 !important;
    }
    .mobile-menu-sidebar{
        margin-left: -300px;
        -webkit-transition: all .4s ease-in-out;
        transition: all .3s ease-in-out;
        background: #131022;
        border-right: 1px solid #28214a;
        position: fixed;
        z-index: 999;
        max-width: 300px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        box-sizing: border-box;
        display: inline;
        -webkit-transition: all .4s ease-in-out;
        transition: all .3s ease-in-out;
        opacity: 1 !important;
        display: block !important;
    }
    .mobile-menu-sidebar.active {
        left: 0;
        margin-left: 0px;
    }
    .mobile-gmail{
        display: block !important;
    }
    .preheader-area {
        display: none !important;
    }
    #header-area {
        .header-bottom-area {
            .main-menu {
                .navbar-nav {
                    li {
                        a.nav-link {
                            margin: 0px 0px !important;
                            text-align: center !important;
                            text-transform: uppercase !important;
                            font-weight: 600 !important;
                            font-size: 16px !important;
                            padding: 15px 0px !important;
                            border-bottom: 1px solid #28214a;
                            position: inherit !important;
                            color: #fff !important;
                            &::before {
                                display: none !important;
                            }
                        }
                        a.selected{
                            &::before {
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .mobile-login-logout-btn{
        display: block !important;
        a {
            display: block;
            width: 100%;
            text-align: center;
            color: #fff;
            border-bottom: 1px solid #28214a;
            padding: 21px 10px;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 600;
        }
    }
    .mobile-gmail {
        display: block;
        text-align: center;
        margin-bottom: 30px;
        padding-top: 18px;
        a{
            color: #fff;
            letter-spacing: .5px;
        }
    }
    .snews_item_single {
        .thumbnail {
            img {
                width: 100%;
            }
        }
    }
    #header-area {
        .header-bottom-area {
            .main-menu {
                .navbar-nav {
                    li {
                        a.nav-link.selected {
                            color: #084CA1 !important;
                        }
                    }
                }
            }
        }
    }
    .forum_dtails_full .content h2 {
        font-size: 32px !important;
        line-height: 38px !important;
    }
    .breadcrumb-section {
        margin-top: 0px !important;
    }




}
@media (max-width:767.99px){
    .section-padding{
        padding: 70px 0px !important;
    }
    .section-padding-2{
        padding-top: 70px !important;
        padding-bottom: 40px !important;
    }
    .about-page-content-wrap.section-padding {
        padding-bottom: 30px !important;
    }
    .single-about-text.about-school-text h2 {
        font-size: 30px !important;
    }
    .slider-content {
        h2 {
            font-size: 40px !important;
            line-height: 55px !important;
        }
        p {
            font-size: 18px !important;
            line-height: 26px !important;
        }
    }
    .breadcrumb-section {
        height: 500px !important;
    }
    .breadcrumb-section {
        .breadcrumb-section-content {
            h2 {
                font-size: 40px !important;
                line-height: 55px !important;
            }
            p {
                font-size: 18px !important;
            }
        }
    }
    .blog-post-full {
        .blog-item-single {
            .content {
                h2.title {
                    font-size: 24px !important;
                    line-height: 28px !important;
                }
            }
        }
    }
    .forum_dtails_full .content h2 {
        font-size: 28px !important;
        line-height: 34px !important;
    }
    .forum_dtails_full .auth .right {
        width: 100%;
        margin-top: 10px;
    }
    .css-1wa3eu0-placeholder {
        line-height: 1.22 !important;
    }
}
@media (max-width:576px){
    .section-padding{
        padding: 50px 0px !important;
    }
    .section-padding-2{
        padding-top: 50px !important;
        padding-bottom: 20px !important;
    }
    .section-headding {
        h2 {
            font-size: 35px !important;
            line-height: 35px !important;
        }
        p {
            font-size: 16px !important;
            line-height: 28px !important;
        }
    }
    .our_message_single {
        .row {
            box-sizing: border-box !important;
            margin: 0 !important;
        }
    }
    .our_message_single{
        .content {
            h4 {
                font-size: 24px !important;
                margin-bottom: 10px !important;
            }
            p{
                font-size: 15px !important;
            }
        }
    }
    .snews_item_single {
        .content {
            h2 {
                font-size: 22px !important;
                line-height: 28px !important;
            }
        }
    }
    #footer-area .footer-widget {
        padding-top: 40px !important;
    }
    #footer-area .footer-widget .single-widget-wrap {
        margin-bottom: 30px !important;
    }
    .single-about-text.about-school-text h2 {
        font-size: 28px !important;
    }
    .single-about-text.about-school-text {
        padding: 25px 10px !important;
    }
    .section-headding {
        margin-bottom: 40px !important;
    }
    .single-teambox-item {
        .content {
            h3.name {
                font-size: 20px !important;
                line-height: 30px !important;
                padding-bottom: 0px !important;
            }
        }
    }
    .About-us-page .single-about-text.body-text {
        padding: 16px !important;
    }
    .slider-content {
        h2 {
            font-size: 30px !important;
            line-height: 38px !important;
        }
    }
    .breadcrumb-section {
        height: 250px !important;
    }
    .breadcrumb-section {
        .breadcrumb-section-content {
            h2 {
                font-size: 30px !important;
                line-height: 38px !important;
            }
            p {
                font-size: 16px !important;
            }
        }
    }
    .s-text-input {
        textarea {
            font-size: 16px !important;
            height: 150px !important;
        }
    }
    .s-text-input {
        input {
            font-size: 16px !important;
        }
    }
    .swu_section_sub {
        display: inherit !important;
        justify-content: space-between;
        .swu_section_sub .swu_content {
            width: 50% !important;
            margin-right: 0px !important;
            float: left !important;
            padding-left: 10px;
        }
    }
    .blog-post-full {
        .blog-item-single {
            .content {
                h2.title {
                    font-size: 20px !important;
                    line-height: 28px !important;
                }
            }
        }
    }
    .blog-pagination ul li a {
        width: 30px !important;
        height: 30px !important;
    }
    .blog-pagination ul li span {
        width: 30px !important;
        height: 30px !important;
    }
    .blog-pagination ul li {
        margin: 2px 4px !important;
    }
    .forum_dtails_full .content h2 {
        font-size: 24px !important;
        line-height: 30px !important;
    }
    .forum_dtails_full .comment-section-top .button-cmsection {
        width: 100%;
        button{
            width: 100%;
        }
    }
    .forum_dtails_full .comment-list-form .comment-list .single-comment {
        padding: 10px !important;
    }
    #header-area .header-bottom-area {
        border-bottom-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
    .breadcrumb-section {
        height: 700px;
        margin-top: 0px !important;
    }
    .home-page-main {
        margin-top: 0px !important;
    }
    .profile-page-main p {
        margin: 0;
        padding: 0;
        font-size: 12px !important;
        margin-right: 0px !important;
    }
    .profile-page-main .duration-group{
        flex-direction: column;
    }
    .profile-page-main .email-phone{
        flex-direction: column;
    }
    .user-details .left-side .content-image {
        height: auto !important;
    }
    .user-details .right-side {
        height: auto !important;
        overflow: hidden;
        max-height: 100% !important;
    }
    .pagination-component {
        .pagination-container {
            .pagination-item {
                width: 35px !important;
                height: 35px !important;
                .arrow.right {
                    margin-top: -2px !important;
                }
                .arrow.left {
                    margin-left: -7px !important;
                    margin-top: -2px !important;
                }
            }
        }
    }
    .password-reset-page {
        .password-reset-container {
            .password-reset-wrapper {
                padding: 36px 20px 45px !important;
                h3 {
                    font-size: 24px;
                    text-align: center;
                    line-height: 1.1;
                    font-weight: 700;
                }
                p{
                    text-align: center;
                }
            }
        }
    }
}
@media (max-width:350px){
    .forum_dtails_full {
        padding: 20px 10px !important;
    }
    .forum_dtails_full .content h2 {
        font-size: 18px !important;
        line-height: 30px !important;
        margin-bottom: 20px !important;
    }
    .section-headding {
        h2 {
            font-size: 30px !important;
            line-height: 35px !important;
        }
    }
    .our_message_single {
        .content{
            margin-top: 15px !important;
            h4 {
                font-size: 20px !important;
            }
            p {
                font-size: 14px !important;
            }
            h5.name {
                font-size: 16px !important;
                margin-bottom: 0px !important;
            }
        }
    }
    .single-about-text.about-school-text .table-responsive {
        margin-top: 0px !important;
    }
    .section-headding {
        margin-bottom: 30px !important;
    }
    .single-teambox-item {
        .thumbnail {
            img {
                width: 250px !important;
                height: 250px !important;
            }
        }
    }
    .blog-post-full {
        .blog-item-single {
            .content {
                h2.title {
                    font-size: 18px !important;
                    line-height: 24px !important;
                }
            }
        }
    }
    .forum_dtails_full .content p {
        font-size: 14px !important;
        line-height: 28px !important;
        margin-bottom: 10px !important;
    }
    .forum_dtails_full .comment-list-form .comment-list .single-comment .content {
        width: 100% !important;
        padding-top: 10px !important;
    }
    .pagination-component {
        .pagination-container {
            .pagination-item {
                width: 30px !important;
                height: 30px !important;
                font-size: 14px !important;
                margin: 0px 2px !important;
                .arrow.left {
                    transform: rotate(-135deg) translate(-36%) !important;
                    margin-left: 0px !important;
                    margin-top: 0px !important;
                }
                .arrow.right {
                    transform: rotate(45deg) translate(-36%) !important;
                    margin-top: 0px !important;
                }
            }
        }
    }
    .mobile-menu-sidebar {
        margin-left: -250px;
        max-width: 250px;
    }
    #header-area .header-bottom-area .main-menu .navbar-nav li a.nav-link {
        font-size: 13px !important;
        padding: 10px 0px !important;
    }
    .register-form-content .signin-area-wrap .sign-form input {
        padding: 5px 1px !important;
    }
    .mobile-login-logout-btn a {
        font-size: 13px;
    }
}