#header-area .header-bottom-area .main-menu .navbar-brand img {
    width: 80px;
}
@media (max-width:576px){
    #header-area .header-bottom-area .main-menu .navbar-brand img {
        width: 70px;
    }
}
#header-area .header-bottom-area.fixed .navbar-brand img {
    width: 55px;
}

#header-area .header-bottom-area {
    background-color: #fff;
    border-bottom: 1px solid gainsboro;
    padding: 16px 0px;
    position: relative;
    z-index: 9;
    opacity: .94;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
}

#header-area .header-bottom-area .main-menu .navbar-nav li a.nav-link {
	padding: 0;
	line-height: 2.72rem;
	margin: 0 20px;
	font-size: 18px;
	font-weight: 400;
	color: #2B3539;
	position: relative;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}
#header-area .header-bottom-area .main-menu .navbar-nav li:last-child a.nav-link{
    margin-right: 0px;
}
#header-area .header-bottom-area .main-menu .navbar-nav li a.nav-link:hover{
    color: #084CA1;
    background: transparent;
}
#header-area .header-bottom-area .main-menu .navbar-nav li a.nav-link.selected{
    color: #084CA1;
    background: transparent;
}
#header-area .header-bottom-area .main-menu .navbar-nav li a.nav-link::before{
    content: "";
    clear: both;
    display: block;
    position: absolute;
    width: 0%;
    height: 2px;
    background: #084CA1;
    left: 0px;
    bottom: 0px;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}
#header-area .header-bottom-area .main-menu .navbar-nav li a.nav-link:hover::before{
    width: 100%;
}
#header-area .header-bottom-area .main-menu .navbar-nav li a.nav-link.selected::before{
    width: 100%;
    height: 2px;
    opacity: 1;
    visibility: visible;
}
.navbar-brand {
    padding-top: 3px;
    padding-bottom: 3px;
}
#header-area .preheader-area {
	background-color: #2B3539;
	padding: 20px 0px;
}
#header-area .preheader-area .preheader-left a i {
	padding-right: 8px;
}
#header-area .preheader-area .preheader-left a {
	font-size: 18px;
	font-weight: 400;
	line-height: 30px;
}
.login-btn {
	padding: 7px 45px !important;
	display: inline-block;
	background: transparent !important;
	color: #fff;
	font-size: 20px !important;
	font-weight: 600;
	border: 2px solid #fff;
	border-radius: 24px;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}
.login-btn:hover{
    background: #084CA1 !important;
    border-color: #084CA1;
}
.signup-btn{
    padding: 7px 45px !important;
	display: inline-block;
	background-color: #084CA1 !important;
	color: #fff;
	font-size: 20px !important;
	font-weight: 600;
	border: 2px solid #084CA1;;
	border-radius: 24px;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}
#header-area .header-bottom-area .main-menu li.nav-item:hover a.nav-link {
	background-color: transparent !important;
}

.alert-warning{
	z-index: 2;
	opacity: 0.9;
}






@media only screen and (min-width: 320px) and (max-width: 575px) {
    .navbar-toggler-icon {
        display: inline-block;
        width: 3.5em;
        height: 2.5em;
    }
}

@media only screen and (max-width: 576px) {
    .navbar-toggler-icon {
        display: inline-block;
        width: 3.5em;
        height: 3.5em;
    }
}
.mobile-gmail{
    display: none;
}
.mobile-login-logout-btn{
    display: none;
}
.header-bottom-area.sticky {
	position: fixed !important;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99;
	/*-webkit-animation: sticky 1s;
	-moz-animation: sticky 1s;
	-o-animation: sticky 1s;
	animation: sticky 1s;*/
	-webkit-box-shadow: 2px 4px 8px rgba(51, 51, 51, 0.25);
	-moz-box-shadow: 2px 4px 8px rgba(51, 51, 51, 0.25);
	box-shadow: 2px 4px 8px rgba(140, 129, 129, 0.062);
	background: #fff;
    padding: 2px 0px !important;
}
/*@-webkit-keyframes sticky {
  	0% {
    	-webkit-transform: translateY(-100%);
    	transform: translateY(-100%); 
    }
  	100% {
    	-webkit-transform: translateY(0%);
    	transform: translateY(0%); 
	} 
}
@keyframes sticky {
  	0% {
    	-webkit-transform: translateY(-100%);
    	transform: translateY(-100%); 
	}
  	100% {
    	-webkit-transform: translateY(0%);
    	transform: translateY(0%); 
	} 
}*/
@media (max-width:991px){
	#header-area .header-bottom-area {
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
		opacity: 1;
	}
		
}
