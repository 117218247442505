.error-404-page {

    .error-404-page__wrapper {

        .main-content {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            //width: calc(100vw - 412px);
            height: 100vh;
            flex-direction: column;
            align-content: stretch;
            flex-wrap: wrap;
            padding: 0 80px;

            &__wrapper {
                background-color: #FAFAFA;
                padding: 42px 68px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                //width: 560px;

                .page-title {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 46px;
                    text-transform: uppercase;
                    color: #013A6D;
                    padding: 25px 0 12px 0;
                }

                h6 {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 21px;
                    color: #013A6D;
                    padding: 0 0 22px 0;
                }

                p {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    text-align: center;
                    color: #4F4F4F;
                }

                .action-block{
                    button:focus{
                        outline: none !important;
                    }
                }
            }

        }
    }

}


@media only screen and (max-width: 767px) {
    // Start Error page responsive code
    .error-404-page {
        &__wrapper {
            .main-content {
                width: 100% !important;
                height: auto !important;
                padding: 0 !important;

                &__wrapper {
                    width: 100% !important;
                    //padding: 35px 20px !important;

                    .page-title {
                        font-size: 30px !important;
                    }

                    h6 {
                        font-size: 18px !important;
                    }
                }
            }
        }
    }
    // End Error page responsive code
}