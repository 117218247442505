.forum_single_widgets {
    border-radius: 5px;
    padding: 15px;
    background: #fff;
    overflow: hidden;

    .height-450{
        height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .auth {
        padding-bottom: 15px;
        margin-bottom: 10px;
        border-bottom: 1px solid #d5d5d59b;
        overflow: hidden;
        display: flex;
        justify-content: space-between;

        .left {
            float: left;
            flex: 0 0 62px;
            width: 62px;
            height: 62px;
            overflow: hidden;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        .right {
            float: right;
            text-align: right;
            overflow: hidden;
            padding-top: 10px;

            p {
                color: #2B3539;
                font-size: 18px;
                font-weight: 400;
                line-height: 20px;
                margin-bottom: 0;
                //text-transform: capitalize;
            }

            span {
                color: #2B3539;
                font-size: 14px;
                line-height: 1;
            }
        }
    }

    .list {
        ul {
            li {
                display: block;
                width: 100%;

                p {
                    cursor: pointer;
                    color: #0b0b0b;
                    font-size: 14px;
                    //line-height: 24px;
                    padding: 2px 0;
                    display: inline-block;
                    -webkit-transition: all .3s ease-in-out;
                    transition: all .3s ease-in-out;
                    font-weight: 400;
                    margin-bottom: 5px;

                    //&:hover {
                    //    text-decoration: underline;
                    //}
                }

                .selected-tab {
                    //font-weight: bold;
                    color: #084CA1;
                    border-left: 5px solid #084CA1;
                    padding-left: 8px;
                }

                .normal-tab {
                    padding-left: 10px;
                }
            }
        }
    }
}

.forum_single_widgets-1 {
    border-radius: 5px;
    background: #fff;
    overflow: hidden;
    border: 1px solid #dddddd91;
    position: relative;

    h2.title {
        background: #4CA1E5;
        color: #fff;
        font-size: 20px;
        text-align: center;
        padding-top: 13px;
        padding-bottom: 13px;
        line-height: 1;
        font-weight: 600;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    h2.title.category {
        background: #E06D70;
    }

    h2.title.joboffer {
        background: #9457D9;
    }

    h2.title.joboffer {
        background: #9457D9;
    }

    h2.title.schollarship {
        background: #DA4EC3;
    }

    .list {
        padding: 1px 20px;
        //min-height: 240px;

        li {
            display: block;
            width: 100%;
            padding-left: 15px;
            position: relative;

            &:after {
                content: "";
                clear: both;
                display: block;
                position: absolute;
                top: 9px;
                left: -4px;
                width: 10px;
                height: 10px;
                //background: #7171712b;
                border-radius: 50%;
                border: 1px solid #4F4F4F;
            }

            a {
                color: #084CA1;
                font-size: 15px;
                //line-height: 24px;
                margin: 8px 0px;
                display: inline-block;
                transition: all 0.3s ease-in-out;
                font-weight: 400;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;

                &:hover {
                    text-decoration: underline;
                }
            }

            &:last-child > a {
                margin-bottom: 15px;
            }
        }

        .se-all {
            padding-top: 15px;
            text-align: right;
            padding-bottom: 10px;

            a {
                color: #084CA1;
                font-size: 16px;
                font-weight: 400;
                -webkit-transition: all .4s ease-in-out;
                transition: all .3s ease-in-out;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.blog-post-forum {
    border-radius: 5px;
    padding: 15px;
    background: #fff;
    overflow: hidden;
    border: 1px solid #dddddd91;

    .make-a-post {
        width: 100%;
        border: 1px solid #8080803d;
        border-radius: 40px;
        text-align: center;
        padding: 10px;
        cursor: pointer;

        &:hover {
            background-color: #f7f7f7;
        }
    }

    h4 {
        font-size: 14px;
        font-weight: 700;
        color: #666;
        line-height: 1.1;
        padding-bottom: 9px;
    }

    .select-category {
        margin-bottom: 20px;

        select {
            border: none;
            background: #fff;
            cursor: pointer;
            color: #666;
            font-size: 18px;
        }
    }

    .textarea {
        margin-bottom: 20px;

        textarea {
            width: 100%;
            border-radius: 8px;
            background: #f7f7f7;
            border: none;
            padding: 16px;
            font-size: 15px;
            color: #2B3539;
            line-height: 28px;
            font-weight: 400;
            height: 120px;
        }
    }

    .bottom {

        .image {
            float: left;
            display: flex;
            align-items: center;
            width: calc(100% - 100px);

            .s-image {
                position: relative;
                margin-right: 16px;
                width: 40px;
                height: 40px;

                img {
                    width: 100%;
                    height: 100%;
                }

                .remove {
                    position: absolute;
                    background: #FE6C72;
                    top: -7px;
                    right: -6px;
                    width: 17px;
                    height: 17px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    border-radius: 50%;
                    padding: 4px;
                    box-sizing: border-box;
                    z-index: 9;
                }
            }

            .up-image {
                label {
                    img {
                        width: 20px;
                        cursor: pointer;
                    }
                }

                input {
                    display: none;
                }
            }
        }

        .post-button {
            float: right;

            button {
                width: 100px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #084CA1;
                color: #fff;
                text-transform: uppercase;
                border: none;
                font-size: 18px;
                font-weight: 600;
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }
}

textarea:focus {
    outline: none;
}

.blog-post-full {
    border-radius: 5px;
    padding: 20px 16px;
    background: #fff;
    //overflow: hidden;
    border: 1px solid #dddddd91;
    margin-bottom: 20px;

    .sort {
        margin-bottom: 20px;

        select {
            border: none;
            background: #fff;
            cursor: pointer;
            color: #666;
            font-size: 18px;
        }
    }

    .blog-item-single {
        border-bottom: 1px solid #00000021;
        padding-bottom: 20px;
        margin-bottom: 20px;

        .thumb {
            .row {
                width: 100%;
                margin: 0 auto;

                .col-6 {
                    padding: 0 2px;
                }
            }

            margin-bottom: 10px;

            img {
                width: 100%;
                margin-bottom: 2px;
            }
        }

        .content {
            .cate_auth_date {
                margin-bottom: 10px;
                -ms-flex-pack: justify;
                justify-content: space-between;
                display: flex;

                .left {
                    float: left;
                    display: flex;

                    .img {
                        float: left;
                        flex: 0 0 48px;
                        width: 48px;
                        height: 48px;
                        margin-right: 10px;
                        border-radius: 50%;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }
                    }

                    .name-cate {
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        h4.name {
                            font-size: 15px;
                            font-weight: 600;
                            line-height: 1.1;
                            color: #4F4F4F;
                            margin-bottom: 0;
                            //text-transform: capitalize;
                        }

                        span {
                            font-size: 13px;
                            color: #4F4F4F;
                            font-weight: 300;

                            a {
                                color: #606060;
                            }
                        }

                        span.date {
                            display: none;
                        }

                        @media(max-width: 576px) {
                            span.date {
                                display: block;
                                line-height: 1;
                            }
                        }
                    }
                }

                .right {
                    float: right;
                    display: flex;
                    justify-content: end;
                    max-width: 104px;
                    width: 100%;
                    text-align: right;
                    .dot-btn {
                        .dot-3 {
                            background: transparent;
                            border: none;
                            color: black;
                            font-size: 18px;
                            padding: 0;
                            margin-left: 10px;

                            &:focus {
                                outline: none;
                                border: none;
                                box-shadow: 0px 0px 0px 0px transparent;
                            }

                            &::after {
                                display: none;
                            }
                        }

                        .dropdown-menu {
                            border-radius: 0;
                            border: none;
                            box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
                            width: 108px;
                            left: -100px !important;
                            padding: 0;

                            .dropdown-item {
                                font-size: 12px;
                                border-bottom: 1px solid #E3E3E3;

                                &:last-of-type {
                                    border-bottom: none;
                                }

                                i {
                                    display: inline-block !important;
                                    font-size: 12px !important;
                                    padding-right: 2px;
                                }

                                .fa-pencil-square-o {
                                    color: #bd9d00;
                                }

                                .fa-trash {
                                    color: red;
                                }
                            }
                        }
                    }

                    span {
                        color: #606060;
                        font-size: 14px;
                        white-space: nowrap;
                    }

                    @media(max-width: 576px) {
                        span {
                            display: none;
                        }
                    }
                }
                @media (max-width:576px){
                    .right {
                        max-width: 10px;
                    }
                }
            }

            .comment-count {
                padding-top: 5px;

                span {
                    display: inline-block;
                    color: #6B7074;
                    font-size: 15px;
                    font-weight: 400;
                    cursor: pointer;
                }
            }

            h2.title {
                color: #2B3539;
                font-size: 28px;
                line-height: 35px;
                margin-bottom: 15px;
                font-weight: 600;

                a {
                    color: #2B3539;
                    -webkit-transition: all .4s ease-in-out;
                    transition: all .3s ease-in-out;

                    &:hover {
                        color: #084CA1;
                    }
                }
            }

            .auth-view {
                overflow: hidden;

                .view {
                    color: #5E6367;
                    font-size: 18px;
                    float: right;
                }
            }

            p {
                font-size: 14px;
                line-height: 22px;
                color: #6B7074;
                margin-bottom: 5px;

                code {
                    font-size: 14px;
                    line-height: 22px;
                    color: #6B7074;
                    margin-bottom: 5px;
                }
            }

            .comment-form-full {
                margin-top: 10px;
                position: relative;

                form {
                    position: relative;
                }

                textarea {
                    width: 100%;
                    font-size: 13px;
                    border: 1px solid #ddd;
                    padding: 5px 10px;
                    border-radius: 8px;
                    height: 45px;
                    overflow-y: hidden;
                    -webkit-transition: all .4s ease-in-out;
                    transition: all .4s ease-in-out;
                    color: #666;
                    padding-right: 50px;

                    &:focus {
                        border-color: #98c0f285;
                    }
                }

                button {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    background: #fff;
                    color: #666;
                    padding: 0px 10px;
                    cursor: pointer;
                    border: 1px solid #ddd;
                    height: 100%;
                    align-items: end;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    box-sizing: border-box;
                    top: 0;
                }
            }
        }

        &:last-child {
            border-bottom: none;
            padding-bottom: 0px;
            margin-bottom: 0px;
        }
    }
}

.comment-list-full {
    margin-top: 15px;

    ul {
        .single-comment {
            margin-bottom: 5px;
            width: 100%;
            display: flex;

            .thum {
                flex: 0 0 53px;
                width: 53px;
                margin-right: 5px;

                img {
                    float: left;
                    width: 48px;
                    height: 48px;
                    margin-right: 10px;
                    border-radius: 50%;
                }
            }

            .cont {
                position: relative;
                background-color: #F2F2F2;
                padding: 0 10px;
                border-radius: 4px;
                width: 100%;

                .dot-btn {
                    position: absolute;
                    top: 2px;
                    right: 4px;

                    .dot-3 {
                        background: transparent;
                        color: black;
                        font-size: 15px;
                        border: none;
                        box-shadow: 0px 0px 0px 0px transparent;
                        padding: 4px 10px;

                        &::after {
                            display: none;
                        }
                    }

                    .dropdown-menu {
                        border-radius: 0;
                        border: none;
                        box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
                        width: 108px;
                        left: -100px !important;
                        padding: 0;

                        .dropdown-item {
                            font-size: 12px;
                            border-bottom: 1px solid #E3E3E3;

                            &:last-of-type {
                                border-bottom: none;
                            }

                            i {
                                display: inline-block !important;
                                font-size: 12px !important;
                                padding-right: 2px;
                            }

                            i.fa.fa-pencil-square-o {
                                color: #bd9d00;
                            }

                            i.fa.fa-trash {
                                color: red;
                            }
                        }
                    }
                }

                a {
                    display: inline-block;

                    .name {
                        font-size: 13px;
                        line-height: 1;
                        color: #4f4f4f;
                        font-weight: bold;
                        margin-top: 7px;
                    }
                }

                p {
                    font-size: 12px;
                    line-height: 18px;
                    word-break: break-word;
                }
            }

        }

        .load-comment {
            font-size: 13px;
            font-weight: 500;
            line-height: 1;
            cursor: pointer;
        }
    }
}

.blog-pagination {
    border-radius: 5px;
    padding: 20px 16px;
    background: #fff;
    border: 1px solid #dddddd91;
}

.blog-post-forum_full {
    position: relative;
    display: flex;

    img {
        flex: 0 0 48px;
        width: 48px;
        height: 48px;
        width: 48px;
        margin-right: 14px;
        border-radius: 50%;
    }

    input {
        width: 100%;
        font-size: 13px;
        outline: none;
        height: 40px;
        padding: 0px 15px;
        border: 1px solid #ddd;
        background: #cccccc12;
        border-radius: 30px;
        color: #5e5959;
    }
}

.modal {
    .modal-header {
        position: relative;
        border-bottom: none !important;
        padding: 18px 18px !important;

        .modal-title {
            margin-bottom: 0 !important;
            font-size: 17px !important;
            font-weight: 700 !important;
            color: #666 !important;
            line-height: 1.1 !important;
        }

        .close {
            margin: -3px -1rem -1rem auto;
            font-size: 30px;
            font-weight: normal;
            padding: 0 1rem 0 0;
            position: absolute;
            top: 16px;
            right: 20px;
            cursor: pointer;
        }
    }

    .modal-content {
        border-radius: 5px !important;
        border: 1px solid #dddddd1c !important;

        .blog-forum-modal-bootstrap-con {
            padding-top: 20px;
            padding-bottom: 25px;
            overflow: inherit !important;
            padding: 20px;
            border-top: 1px solid #dddddd63;

            .css-yk16xz-control {
                border-color: hsla(0, 0%, 80%, 0.29) !important;
                color: #666;

                &:focus {
                    outline: none !important;
                }

                &:hover {
                    border: 1px solid !important;
                    border-color: hsla(0, 0%, 80%, 0.29) !important;
                }
            }

        }

        .action-block.w-100.justify-content-between.mt-3 {
            display: inherit !important;

            .form-submit.post-button {
                width: 100% !important;
                font-weight: 500 !important;
                font-size: 14px !important;
                text-transform: capitalize !important;
                letter-spacing: 2px;
                padding: 0px 0px !important;
                height: 35px !important;
                border-radius: 4px !important;
                margin-top: 5px;
                -webkit-transition: all .4s ease-in-out;
                transition: all .3s ease-in-out;
                background: #0072d9 !important;

                &:hover {
                    background: #04335e !important;
                }
            }
        }
    }
}

.modal-backdrop.show {
    opacity: 0.7 !important;
}

.editorStyles_editor__3pBnk {
    margin-top: 15px;
    height: 120px;
    box-shadow: 0px 0px 0px 0px #ddd;
    border: 1px solid #ddd;
    overflow: hidden;
    overflow-y: auto;
}

.editorStyles_editor__3pBnk .public-DraftEditor-content {
    min-height: auto !important;
}

.comment-form-full .editorStyles_editor__3pBnk {
    height: 34px !important;
    padding: 8px 16px !important;
    color: #666 !important;
    font-size: 14px !important;
    line-height: 1.1 !important;
}

.pt-30 {
    padding-top: 30px;
}

.blog-post-forum, .blog-post-full, .forum_single_widgets, .forum_single_widgets-1 {
    margin-bottom: 15px;
    border: 1px solid #d5d5d59b;
}

a:hover {
    text-decoration: none !important;
}

@media (max-width:576px){
    .modal .modal-content {
        min-height: 250px;
        overflow-y: auto;
    }
    .modal .modal-header {
        padding: 10px 18px !important;
    }
    .modal .modal-header .close {
        margin: -14px -1rem -1rem auto;
    }
}
