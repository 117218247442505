#footer-area {
    .footer-widget {
        background-color: #2B3539;
        padding: 60px 0px !important;
        .single-widget-wrap{
            .widget-title {
                margin-bottom: 10px !important;
                font-size: 26px;
                text-transform: capitalize;
                font-weight: 600;
            }
            .widgei-body{
                .gmail {
                    display: inline-block;
                    font-size: 16px;
                    color: #fff;
                    margin-bottom: 7px;
                    i{
                        padding-right: 5px;
                    }
                }
                .location {
                    display: flex;
                    i {
                    font-size: 20px;
                    color: #fff;
                    padding-right: 11px;
                    padding-top: 5px;
                    }
                    p{
                        color: #fff;
                        font-size: 16px;
                        padding-top: 0px;
                    }
                }
            }
            .footer-list{
                li{
                    a{
                        display: inline-block;
                        font-size: 16px;
                        line-height: 32px;
                        text-decoration: underline;
                        &::before {
                            display: none !important;
                        }
                        &:hover{
                            padding-left: 0px !important;
                        }
                    }
                }
            }
        }
        .footer-social-icons{
            a {
                display: inline-block;
                i{
                    display: flex !important;
                    justify-content: center !important;
                    align-items: center;
                    width: 40px !important;
                    height: 40px !important;
                    background: #ffffff29 !important;
                    border-radius: 50%;
                    -webkit-transition: all .4s ease-in-out;
                    transition: all .3s ease-in-out;
                    &:hover{
                        // background: #084CA1 !important;
                        color: #fff !important;
                        opacity: 1 !important;
                    }
                }
                .fa-youtube-play{
                    &:hover{
                        background-color: #FF0000 !important;
                    }
                }
                .fa-facebook{
                    &:hover{
                        background-color: #1195F4 !important;
                    }
                }
                .fa-linkedin{
                    &:hover{
                        background-color: #0073B2 !important;
                    }
                }
                .fa-twitter{
                    &:hover{
                        background-color: #1C93E4 !important;
                    }
                }
                .fa-instagram{
                    &:hover{
                        background-color: #533DD2 !important;
                    }
                }
            }
        }
    }
    .footer-bottom {
        padding: 20px 0 !important;
        background: #2B3539;
        border-top: 2px solid #ffffff1f;
        p{
            font-size: 16px;
        }
    }
}